<template>
  <div>
    <wd-navbar>
      <div slot="left" class="middle" @click="$goBack">
        <wd-icon name="arrow-left" />
      </div>
      <div>
        <span>选择缴费设备</span>
      </div>
    </wd-navbar>
    <wd-tabs v-model="tab" @change="changeTabs">
      <wd-tab title="电表缴费">
        <div
          class="searchWrap"
          style="display: flex; align-items: center; justify-content: center"
        >
          <wd-search
            v-model="treeInfo.electric.treeName"
            placeholder="请输入表名称"
            style="width: 80%"
            cancel-txt="搜索"
            class="search"
            @clear="getTreeList('electric')"
            @cancel="getTreeE"
          ></wd-search>
        </div>

        <el-tree
          class="my_tree"
          :data="treeInfo.electric.treeData"
          :props="treeInfo.electric.defaultProps"
          :default-expanded-keys="treeInfo.electric.treeDefaultExpanded"
          :filter-node-method="filterNode"
          node-key="girlFriendNodeId"
          ref="tree1"
        >
          <span class="custom-tree-node" slot-scope="{ data }">
            <span>{{ data.girlFriendNodeName }}</span>
            <span
              :class="
                data.girlFriendNodeType == '2' ? 'custom-tree-node-span' : ''
              "
            >
              <el-button
                v-if="data.girlFriendNodeType == '2'"
                type="text"
                size="mini"
                style="color: #ffffff"
                @click="confirmBindTab(data)"
                >绑定</el-button
              >
            </span>
          </span>
        </el-tree>
      </wd-tab>
      <wd-tab title="水表缴费">
        <div
          class="searchWrap"
          style="display: flex; align-items: center; justify-content: center"
        >
          <wd-search
            v-model="treeInfo.water.treeName"
            placeholder="请输入表名称"
            style="width: 80%"
            cancel-txt="搜索"
            class="search"
            @clear="getTreeList('water')"
            @cancel="getTreeW"
          ></wd-search>
        </div>
        <el-tree
          class="my_tree"
          :data="treeInfo.water.treeData"
          :props="treeInfo.water.defaultProps"
          :default-expanded-keys="treeInfo.water.treeDefaultExpanded"
          :filter-node-method="filterNode"
          node-key="girlFriendNodeId"
          ref="tree2"
        >
          <span class="custom-tree-node" slot-scope="{ data }">
            <span>{{ data.girlFriendNodeName }}</span>
            <span
              :class="
                data.girlFriendNodeType == '2' ? 'custom-tree-node-span' : ''
              "
            >
              <el-button
                v-if="data.girlFriendNodeType == '2'"
                type="text"
                size="mini"
                style="color: #ffffff"
                @click="confirmBindTab(data)"
                >绑定</el-button
              >
            </span>
          </span>
        </el-tree>
      </wd-tab>
      <!-- <wd-tab title="设备缴费">
        <div>
          <div
            class="searchWrap"
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <wd-drop-menu class="menu" style="width: 20%">
              <wd-drop-menu-item
                style="top: 50px"
                @change="getList"
                v-model="equipmentConfig.searchObject.equipmentType"
                :options="equipmentConfig.searchObject.typeList"
              />
            </wd-drop-menu>
            <wd-search
              v-model="equipmentConfig.equipmentName"
              placeholder="请输入设备名称"
              style="width: 80%"
              cancel-txt="搜索"
              class="search"
              @cancel="getList"
            ></wd-search>
          </div>
          <wd-pull-refresh
            v-model="equipmentConfig.isLoading"
            @refresh="refresh"
          >
            <div class="list" v-if="equipmentConfig.tableData.length > 0">
              <wd-cell
                class="cellList"
                v-for="(item, index) in equipmentConfig.tableData"
                :key="index"
              >
                <div slot="title">
                  {{ item.equipmentName }}
                </div>
                <div slot="label">
                  类型：{{
                    item.equipmentType == "0"
                      ? "电表"
                      : item.equipmentType == "1"
                      ? "水表"
                      : "--"
                  }}
                </div>
                <div
                  slot="icon"
                  style="display: flex; align-items: center; margin-right: 10px"
                >
                  <img
                    v-if="item.equipmentType == '0'"
                    src="../../assets/img/ele.svg"
                    style="width: 40px"
                  />
                  <img
                    v-else-if="item.equipmentType == '1'"
                    src="../../assets/img/water.svg"
                    style="width: 40px"
                  />
                  <img
                    v-else
                    src="../../assets/img/other.svg"
                    style="width: 40px"
                  />
                </div>
                <div>
                  <wd-button
                    type="info"
                    size="small"
                    icon="wd-icon-check"
                    @click="bindAcct(item.acctId)"
                    >选择</wd-button
                  >
                </div>
              </wd-cell>

              <wd-infinite-load
                ref="loadmore"
                @loadmore="loadmore"
                v-if="equipmentConfig.showLoading"
                :loading="loading"
              ></wd-infinite-load>
            </div>
            <div v-else>
              <wd-status-tip type="content" tip="暂无内容" />
            </div>
          </wd-pull-refresh>
        </div>
      </wd-tab> -->
      <wd-tab title="组合缴费">
        <div>
          <div
            class="searchWrap"
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <wd-search
              v-model="roleConfig.payUserName"
              placeholder="请输入组合名称"
              style="width: 100%"
              cancel-txt="搜索"
              class="search"
              @cancel="getRoleList"
            ></wd-search>
          </div>
          <wd-pull-refresh v-model="roleConfig.isLoading" @refresh="refresh">
            <div class="list" v-if="roleConfig.tableData.length > 0">
              <wd-cell
                class="cellList"
                v-for="(item, index) in roleConfig.tableData"
                :key="index"
              >
                <div slot="title">
                  {{ item.payUserName }}
                </div>
                <div slot="label">业主姓名：{{ item.custName }}</div>
                <div
                  slot="icon"
                  style="display: flex; align-items: center; margin-right: 10px"
                >
                  <img
                    src="../../assets/img/groupInfo.svg"
                    style="width: 40px"
                  />
                </div>
                <div>
                  <wd-button
                    type="info"
                    size="small"
                    icon="wd-icon-check"
                    @click="bindAcct(item.acctId)"
                    >选择</wd-button
                  >
                </div>
              </wd-cell>
              <wd-infinite-load
                ref="loadmore"
                @loadmore="loadmore"
                v-if="roleConfig.showLoading"
                :loading="loading"
              ></wd-infinite-load>
            </div>
            <div v-else>
              <wd-status-tip type="content" tip="暂无内容" />
            </div>
          </wd-pull-refresh>
        </div>
      </wd-tab>
      <!-- <wd-tab title="其他缴费">
        <div>
          <div
            class="searchWrap"
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <wd-search
              v-model="otherConfig.otherFeeName"
              placeholder="请输入其他缴费名称"
              style="width: 100%"
              cancel-txt="搜索"
              class="search"
              @cancel="getOtherList"
            ></wd-search>
          </div>
          <wd-pull-refresh v-model="otherConfig.isLoading" @refresh="refresh">
            <div class="list" v-if="otherConfig.tableData.length > 0">
              <wd-cell
                class="cellList"
                v-for="(item, index) in otherConfig.tableData"
                :key="index"
              >
                <div slot="title">
                  {{ item.otherFeeName }}
                </div>
                <div slot="label">业主姓名：{{ item.custName }}</div>
                <div
                  slot="icon"
                  style="display: flex; align-items: center; margin-right: 10px"
                >
                  <img src="../../assets/img/other.svg" style="width: 40px" />
                </div>
                <div>
                  <wd-button
                    type="info"
                    size="small"
                    icon="wd-icon-check"
                    @click="bindAcct(item.acctId)"
                    >选择</wd-button
                  >
                </div>
              </wd-cell>
              <wd-infinite-load
                ref="loadmore"
                @loadmore="loadmore"
                v-if="otherConfig.showLoading"
                :loading="loading"
              ></wd-infinite-load>
            </div>
            <div v-else>
              <wd-status-tip type="content" tip="暂无内容" />
            </div>
          </wd-pull-refresh>
        </div>
      </wd-tab> -->
    </wd-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      treeInfo: {
        electric: {
          treeName: "",
          treeData: [],
          treeDefaultExpanded: [],
          defaultProps: {
            children: "nodeList",
            label: "girlFriendNodeName",
          },
        },
        water: {
          treeName: "",
          treeData: [],
          treeDefaultExpanded: [],
          defaultProps: {
            children: "nodeList",
            label: "girlFriendNodeName",
          },
        },
      },

      tab: 0,
      roleKey: null,
      loading: false,
      loadingData: true,
      equipmentConfig: {
        pageConfig: {
          pageNum: 1,
          pageSize: 15,
          total: 0,
        },
        equipmentName: "",
        searchObject: {
          equipmentType: "",
          typeList: [
            {
              value: "",
              label: "全部",
            },
            {
              value: "0",
              label: "电表",
            },
            {
              value: "1",
              label: "水表",
            },
          ],
        },
        tableData: [],
        isLoading: false,
        showLoading: false,
      },
      roleConfig: {
        pageConfig: {
          pageNum: 1,
          pageSize: 15,
          total: 0,
        },
        payUserName: "",
        searchObject: {
          type: 0,
          typeList: [
            {
              value: 0,
              label: "全部",
            },
            {
              value: 1,
              label: "电表",
            },
            {
              value: 2,
              label: "水表",
            },
          ],
        },
        tableData: [],
        isLoading: false,
        showLoading: false,
      },
      otherConfig: {
        pageConfig: {
          pageNum: 1,
          pageSize: 15,
          total: 0,
        },
        otherFeeName: "",
        searchObject: {
          type: 0,
          typeList: [
            {
              value: 0,
              label: "全部",
            },
            {
              value: 1,
              label: "电表",
            },
            {
              value: 2,
              label: "水表",
            },
          ],
        },
        tableData: [],
        isLoading: false,
        showLoading: false,
        appUserId: null,
      },
    };
  },
  created() {
    this.roleKey = localStorage.getItem("roleKey");
    this.appUserId = localStorage.getItem("appUserId");
    this.getTreeList("electric");
  },
  methods: {
    confirmBindTab(iteminfo) {
      this.$messageBox
        .confirm(`是否绑定${iteminfo.girlFriendNodeName}？`, "提示")
        .then(
          () => {
            this.$http
              .post("/external/appUserAcct/treeadd", {
                girlFriendId: iteminfo.girlFriendId,
                roleId: this.roleKey,
                appUserId: this.appUserId,
              })
              .then((res) => {
                if (res.code == 200) {
                  this.$toast.success("绑定成功！");
                  setTimeout(() => {
                    this.$router.push("/pay");
                  }, 300);
                } else {
                  this.$toast.error(res.msg);
                }
              });
          },
          (error) => {
            console.log(error);
          }
        );
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.girlFriendNodeName.indexOf(value) !== -1;
    },
    getTreeE() {
      console.log("electric");
      if (this.treeInfo.electric.treeName.trim() == "") {
        this.getTreeList("electric");
      } else {
        this.$refs.tree1.filter(this.treeInfo.electric.treeName);
      }
    },
    getTreeW() {
      console.log("water");
      if (this.treeInfo.water.treeName.trim() == "") {
        this.getTreeList("water");
      } else {
        this.$refs.tree2.filter(this.treeInfo.water.treeName);
      }
    },
    getTreeList(getTreeType) {
      let params = {
        roleId: this.roleKey,
        isPayfee: "0",
        treeType: null,
      };
      if (getTreeType === "electric") {
        params.treeType = "0";
      } else if (getTreeType === "water") {
        params.treeType = "1";
      }

      this.$http.get("/system/girlFriendTree/list", { params: params }).then(
        (value) => {
          let { girlFriendTreeId } = value.rows[0];
          this.$http.get("/system/girlFriendNode/" + girlFriendTreeId).then(
            (value) => {
              // console.log(value);
              this.treeInfo[getTreeType].treeData = value.data;
              // this.treeInfo[getTreeType].treeDefaultExpanded[0] =
              //   value.data[0].girlFriendNodeId;
            },
            (reason) => {
              console.log(reason);
            }
          );
        },
        () => {}
      );
    },

    changeTabs(index) {
      if (index == 0) {
        this.getTreeList("electric");
      } else if (index == 1) {
        this.getTreeList("water");
        // } else if (index == 2) {
        //   this.equipmentConfig.showLoading = false;
        //   this.equipmentConfig.pageConfig.pageNum = 1;
        //   this.getList();
        // } else if (index == 3) {
      } else if (index == 2) {
        this.roleConfig.showLoading = false;
        this.roleConfig.pageConfig.pageNum = 1;
        this.getRoleList();
      }
    },
    getRoleList() {
      let params = {
        roleKey: this.roleKey,
        pageNum: this.roleConfig.pageConfig.pageNum,
        pageSize: this.roleConfig.pageConfig.pageSize,
      };
      if (this.roleConfig.payUserName && this.roleConfig.payUserName != "") {
        params.payUserName = this.roleConfig.payUserName;
      }
      if (this.roleConfig.pageConfig.pageNum == 1) {
        this.roleConfig.tableData = [];
      }
      this.$http
        .get("/external/composeUserPay/list", { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.roleConfig.isLoading = false;
            this.loadingData = true;
            this.roleConfig.showLoading = true;
            this.roleConfig.tableData = this.roleConfig.tableData.concat(
              res.rows
            );
            this.roleConfig.pageConfig.total = res.total;
            if (this.roleConfig.tableData.length == res.total) {
              this.roleConfig.showLoading = false;
            }
          }
        });
    },
    getOtherList() {
      let params = {
        roleKey: this.roleKey,
        pageNum: this.otherConfig.pageConfig.pageNum,
        pageSize: this.otherConfig.pageConfig.pageSize,
      };
      if (
        this.otherConfig.otherFeeName &&
        this.otherConfig.otherFeeName != ""
      ) {
        params.otherFeeName = this.otherConfig.otherFeeName;
      }
      if (this.otherConfig.pageConfig.pageNum == 1) {
        this.otherConfig.tableData = [];
      }
      this.$http.get("/external/def/list", { params: params }).then((res) => {
        if (res.code == 200) {
          this.otherConfig.isLoading = false;
          this.loadingData = true;
          this.otherConfig.showLoading = true;
          this.otherConfig.tableData = this.otherConfig.tableData.concat(
            res.rows
          );
          this.otherConfig.pageConfig.total = res.total;
          if (this.otherConfig.tableData.length == res.total) {
            this.otherConfig.showLoading = false;
          }
        }
      });
    },
    loadmore() {
      if (this.loadingData) {
        this.loadingData = false;
        setTimeout(() => {
          // if (this.tab == 0) {
          //   this.getTreeList("electric");
          // } else if (this.tab == 1) {
          //   this.getTreeList("water");
          // } else
          // if (this.tab == 2) {
          //   this.equipmentConfig.showLoading = false;
          //   this.equipmentConfig.pageConfig.pageNum += 1;
          //   this.getList();
          // } else if (this.tab == 3) {
          if (this.tab == 2) {
            this.roleConfig.showLoading = false;
            this.roleConfig.pageConfig.pageNum += 1;
            this.getRoleList();
          }
        }, 500);
      }
    },
    getList() {
      let params = {
        roleKey: this.roleKey,
        pageNum: this.equipmentConfig.pageConfig.pageNum,
        pageSize: this.equipmentConfig.pageConfig.pageSize,
      };
      if (
        this.equipmentConfig.searchObject.equipmentType &&
        this.equipmentConfig.searchObject.equipmentType != ""
      ) {
        params.equipmentType = this.equipmentConfig.searchObject.equipmentType;
      }
      if (
        this.equipmentConfig.equipmentName &&
        this.equipmentConfig.equipmentName != ""
      ) {
        params.equipmentName = this.equipmentConfig.equipmentName;
      }
      if (this.equipmentConfig.pageConfig.pageNum == 1) {
        this.equipmentConfig.tableData = [];
      }
      this.$http
        .get("/external/equipment/list", { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.equipmentConfig.isLoading = false;
            this.loadingData = true;
            this.equipmentConfig.showLoading = true;
            this.equipmentConfig.tableData = this.equipmentConfig.tableData.concat(
              res.rows
            );
            this.equipmentConfig.pageConfig.total = res.total;

            if (this.equipmentConfig.tableData.length == res.total) {
              this.equipmentConfig.showLoading = false;
            }
          }
        });
    },
    refresh() {
      setTimeout(() => {
        // if (this.tab == 0) {
        //   this.getTreeList("electric");
        // } else if (this.tab == 1) {
        //   this.getTreeList("water");
        // } else
        // if (this.tab == 2) {
        //   this.equipmentConfig.showLoading = false;
        //   this.equipmentConfig.pageConfig.pageNum = 1;
        //   this.getList();
        // } else if (this.tab == 3) {
        if (this.tab == 2) {
          this.roleConfig.showLoading = false;
          this.roleConfig.pageConfig.pageNum = 1;
          this.getRoleList();
        }
      }, 1000);
    },
    bindAcct(acctId) {
      this.$http
        .post("/external/appUserAcct", {
          acctId: acctId,
          roleId: this.roleKey,
          appUserId: this.appUserId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$toast.success("绑定成功！");
            setTimeout(() => {
              this.$router.push("/pay");
            }, 300);
          } else {
            this.$toast.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.searchWrap {
  .search {
    /deep/ .wd-search__cancel {
      font-size: 14px;
    }
  }
}
.cellList {
  /deep/ .wd-cell__wrapper {
    align-items: center;
  }
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 20px;
}

.my_tree {
  background: #ffffff;
  color: #333333;

  /* 节点样式 */
  /deep/ .el-tree-node__content {
    height: 55px !important;
    border-left: 4px solid #ffffff !important;
  }

  // 鼠标滑过效果
  /deep/.el-tree-node__content:hover {
    background-color: rgba(224, 233, 255, 0.3) !important;
  }

  /* 点击后的样式 */
  /deep/.el-tree-node:focus > .el-tree-node__content {
    background-color: #e0e9ff !important;
    border-left: 4px solid #6189f8 !important;
  }

  /* 高亮 */
  .el-tree-node.is-current > .el-tree-node__content {
    background-color: #e0e9ff !important;
    border-left: 4px solid #6189f8 !important;
  }

  /* 每个节点后面内容样式 */
  .custom-tree-node-span {
    width: 50px;
    height: 30px;
    background: #5782f8;
    box-shadow: 0 0 5px #adb5bd;
    margin-right: 10px;
    text-align: center;
    line-height: 30px;
    border-radius: 50px;
  }
}
</style>
